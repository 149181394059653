import { addModuleName } from '../../../tools/addModuleNames';

const MODULE_NAME = 'notifications';

/* eslint-disable */
enum mutationsNames {
    RECEIVE_SIDE_NOTIFICATIONS = 'RECEIVE_SIDE_NOTIFICATIONS',
    CLEAR_SIDE_NORIFICATIONS = 'CLEAR_SIDE_NORIFICATIONS',
    DELETE_SIDE_NOTIFICATON = 'DELETE_SIDE_NOTIFICATON',
    RECEIVE_HEADER_NOTIFICATION = 'RECEIVE_HEADER_NOTIFICATION',
    RECEIVE_COUNTER = 'RECEIVE_COUNTER',
    ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS',
    SET_LAST_NOTIFICATION = 'SET_LAST_NOTIFICATION',
    RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS',
    REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
    SHOW_ACHIEVEMENT_MODAL = 'SHOW_ACHIEVEMENT_MODAL',
    SET_ACHIEVEMENT = "SET_ACHIEVEMENT",
}

enum actionsNames {
    throwNotification = 'throwNotification',
    getLastNotification = 'getLastNotification',
}
/* eslint-enable */

const notificationsMutations = {
    [mutationsNames.RECEIVE_SIDE_NOTIFICATIONS]: addModuleName(
        MODULE_NAME,
        mutationsNames.RECEIVE_SIDE_NOTIFICATIONS,
    ),
    [mutationsNames.CLEAR_SIDE_NORIFICATIONS]: addModuleName(
        MODULE_NAME,
        mutationsNames.CLEAR_SIDE_NORIFICATIONS,
    ),
    [mutationsNames.DELETE_SIDE_NOTIFICATON]: addModuleName(
        MODULE_NAME,
        mutationsNames.DELETE_SIDE_NOTIFICATON,
    ),
    [mutationsNames.RECEIVE_HEADER_NOTIFICATION]: addModuleName(
        MODULE_NAME,
        mutationsNames.RECEIVE_HEADER_NOTIFICATION,
    ),
    [mutationsNames.RECEIVE_COUNTER]: addModuleName(
        MODULE_NAME,
        mutationsNames.RECEIVE_COUNTER,
    ),
    [mutationsNames.ADD_NOTIFICATIONS]: addModuleName(
        MODULE_NAME,
        mutationsNames.ADD_NOTIFICATIONS,
    ),
    [mutationsNames.SET_LAST_NOTIFICATION]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_LAST_NOTIFICATION,
    ),
    [mutationsNames.RESET_NOTIFICATIONS]: addModuleName(
        MODULE_NAME,
        mutationsNames.RESET_NOTIFICATIONS,
    ),
    [mutationsNames.REMOVE_NOTIFICATION]: addModuleName(
        MODULE_NAME,
        mutationsNames.REMOVE_NOTIFICATION,
    ),
    [mutationsNames.SHOW_ACHIEVEMENT_MODAL]: addModuleName(
        MODULE_NAME,
        mutationsNames.SHOW_ACHIEVEMENT_MODAL,
    ),
    [mutationsNames.SET_ACHIEVEMENT]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_ACHIEVEMENT,
    ),
};

const notificationsActions = {
    [actionsNames.throwNotification]: addModuleName(
        MODULE_NAME,
        actionsNames.throwNotification,
    ),
    [actionsNames.getLastNotification]: addModuleName(
        MODULE_NAME,
        actionsNames.getLastNotification,
    ),
};

export {
    mutationsNames,
    notificationsMutations,
    actionsNames,
    notificationsActions,
};