import { AdminState } from '@/store/modules/admin/types';
import { CurrencyState } from '@/store/modules/currency/types';
import { DashboardState } from '@/store/modules/dashboard/types';
import { DonationState } from '@/store/modules/donations/types';
import { DreamsState } from '@/store/modules/dreams/types';
import { GeneralState } from '@/store/modules/general/types';
import { NewsState } from '@/store/modules/news/types';
import { OperatorsState } from '@/store/modules/operators/types';
import { User, UsersState } from '@/store/modules/users/types';
import { WebsocketState } from '@/store/modules/websocket/types';
import { ILogState } from '@/store/modules/log/types';

export type Rout = {
    path: string;
    name: string;
    view: string;
};

export type Theme = {
    [key: string]: string;
};

export interface Level {
    name: string;
    amount: number;
    number: number;
}
export interface Application {
    id?: number;
    name?: string;
    description?: string;
    color?: string;
    logo?: string;
    parentKeyName?: string;
    keyName?: string;
    isActive?: boolean;
    levels?: [Level];
}

export interface AppRequest {
    limit?: number;
    offset?: number;
    order?: string;
    isActive?: boolean;
}

export interface Country {
    id: number | string;
    title: string;
}

export enum OrderStatus {
    NEW = 10,
    IN_PROCESS = 20,
    COMPLETE = 30,
    AUTO_COMPLETE = 40,
    FAILED = 50,
}

export interface PaymentData {
    bankName: string;
    accountNumber: string;
    holderName: string;
}

export interface Order {
    id?: number;
    userId?: number;
    user?: User;
    appId?: number;
    application?: Application;
    productId: string;
    status: OrderStatus;
    donations?: Donation[];
    createdByOperatorId?: number;
}

export interface Donation {
    id?: number;
    level?: Level;
    amount?: number;
    order?: Order;
    status?: OrderStatus;
    paymentData?: PaymentData;
    parentProductId?: string;
}

export interface Payments {
    application: Application;
    user: Order;
    donations: Donation;
}

export interface Response<T> {
    data?: T;
    status: number;
}

export interface Login {
    username: string;
    password: string;
}

export interface QueryParams {
    limit?: number;
    offset?: number;
    order?: string;
    query?: string;
    language?: string;
    isPublished?: boolean;
}

export interface IReview {
    id: number;
    name: string | null;
    photo: null | Blob | File;
    text: string | null;
    lang: string | null;

    // eslint-disable-next-line
    is_active: boolean;
    sort: number;
}

/* eslint-disable */
export interface IRelationItem {
    position_id: number;
    dream_id: number;
    dream_title: string;
    link_type: string;
    can_edit_link: boolean;
}

export interface IRelations {
    position_id: number;
    dream_id: number;
    dream_title: string;
    parent: IRelationItem;
    childs: IRelationItem[];
}
/* eslint-enable */

export interface ComposedState {
    admin: AdminState;
    currency: CurrencyState;
    dashboard: DashboardState;
    donations: DonationState;
    dreams: DreamsState;
    general: GeneralState;
    news: NewsState;
    operators: OperatorsState;
    users: UsersState;
    websocket: WebsocketState;
    logs: ILogState;
};

export interface ILikesConfig {
    id: number;
    /* eslint-disable camelcase */
    min_likes: number;
    max_likes: number;
    is_active: boolean;
    /* eslint-enable camelcase */
    days: number;
};

export interface IViewsConfig {
    id: number;
    /* eslint-disable camelcase */
    min_views: number;
    max_views: number;
    is_active: boolean;
    /* eslint-enable camelcase */
    days: number;
};

export interface ILang {
    [key: string]: string | number;
}

export interface ILoadFile {
    status?: string;
    /* eslint-disable camelcase */
    error_code?: number;
    error_message?: string;
};

export enum consts {
    SHORTCUT_DREAM = 'shortcutDream',
}
