import { adminActions } from '@/store/modules/admin/names';
import { generalMutations } from '@/store/modules/general/names';
import { Store } from 'vuex';
import { reconnectWS } from '../plugins/websocket/reconnect';

export const initApp = async (store: Store<any>) => {
    const refresh = await store.dispatch(adminActions.refresh);

    if (refresh) {
        await store.dispatch(adminActions.regularRefresh);

        const { access } = store.state.admin;
        const isOpen = store.state.websocket.socketIsOpen;

        // @ts-ignore
        reconnectWS(isOpen, access, null);
    }

    store.commit(generalMutations.TOGGLE_INIT_STATUS, false);
};