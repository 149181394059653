import { WebsocketState } from './types';
import { MutationTree } from 'vuex';
import { mutationsNames } from './names';

export const mutations: MutationTree<WebsocketState> = {
    [mutationsNames.SOCKET_ONOPEN](state) {
        state.socketIsOpen = true;
    },

    [mutationsNames.SOCKET_ONCLOSE](state) {
        state.socketIsOpen = false;
        state.loading = false;
        state.connection = false;
    },

    [mutationsNames.TOGGLE_LOADING](state, status: boolean) {
        state.loading = status;
    },

    [mutationsNames.SET_CONNECTION_STATE](state, payload: boolean) {
        state.connection = payload;
    },
};
