import Vue from 'vue';

import axios from 'axios';

Vue.prototype.$http = axios;

export const instance = axios.create({
    baseURL: process.env.VUE_APP_URL_REST,
    withCredentials: true,

    // @ts-ignore
    crossDomain: true,
});

export default instance;