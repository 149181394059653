import { actions } from './actions';
import { mutations } from './mutations';
import { ILogState } from './types';

const state: ILogState = {
    logs: [],
    total: 0,
    notificationsLogs: [],
    notificationsTotal: 0,
};

export default {
    namespaced: true,
    state: () => state,
    actions,
    mutations,
};