import Vue from 'vue';

export const reconnectWS = async (isOpen: boolean, token: string) => {
    if (isOpen) {
        await Vue.prototype.$disconnect();
    }
    const wsUrl = process.env.VUE_APP_WS;

    await Vue.prototype.$connect(`${wsUrl}?ws_token=${token}`);
};
