import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'websocket';

/* eslint-disable */
enum mutationsNames {
    SOCKET_ONOPEN = 'SOCKET_ONOPEN',
    SOCKET_ONCLOSE = 'SOCKET_ONCLOSE',
    TOGGLE_LOADING = 'TOGGLE_LOADING',
    SET_CONNECTION_STATE = 'SET_CONNECTION_STATE',
}
/* eslint-enable */

const websocketMutations = {
    [mutationsNames.SOCKET_ONOPEN]: addModuleName(
        MODULE_NAME,
        mutationsNames.SOCKET_ONOPEN,
    ),
    [mutationsNames.SOCKET_ONCLOSE]: addModuleName(
        MODULE_NAME,
        mutationsNames.SOCKET_ONCLOSE,
    ),
    [mutationsNames.TOGGLE_LOADING]: addModuleName(
        MODULE_NAME,
        mutationsNames.TOGGLE_LOADING,
    ),
    [mutationsNames.SET_CONNECTION_STATE]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_CONNECTION_STATE,
    ),
};

export {
    mutationsNames,
    websocketMutations,
};