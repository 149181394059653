import { WebsocketState } from './types';
import { mutations } from './mutations';

const state: WebsocketState = {
    socketIsOpen: false,
    loading: false,
    connection: false,
};

export default {
    namespaced: true,
    state,
    mutations,
};
