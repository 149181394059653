import { addModuleName } from '../../../tools/addModuleNames';

const MODULE_NAME = 'auth';

/* eslint-disable */
enum mutationsNames {
    UPDATE_CURRENT = 'UPDATE_CURRENT',
    UPDATE_RESET_PASS_JWT = 'UPDATE_RESET_PASS_JWT',
    UPDATE_CONFIRM_EMAIL_TOKEN = 'UPDATE_CONFIRM_EMAIL_TOKEN',
    RECEIVE_ACCESS_TOKEN = 'RECEIVE_ACCESS_TOKEN',
    RECEIVE_FACEBOOK_TOKEN = 'RECEIVE_FACEBOOK_TOKEN',
    RECEIVE_FB = 'RECEIVE_FB',
    RECEIVE_USER = 'RECEIVE_USER',
    RECEIVE_TRIAL_AFTER_SUBSCRIPTION = 'RECEIVE_TRIAL_AFTER_SUBSCRIPTION',
    SET_NEW_PASSWORD_INFO = 'SET_NEW_PASSWORD_INFO',
    SET_RECOVERY_PASSWORD = 'SET_RECOVERY_PASSWORD',
    SET_REFCODE = 'SET_REFCODE',
    SET_REFERAL = 'SET_REFERAL',
    SET_TOKEN = 'SET_TOKEN',
    SET_PERFOMANCE = 'SET_PERFOMANCE',
    SET_USER_WITHOUT_SOME_REQUIRED_FIELD = 'SET_USER_WITHOUT_SOME_REQUIRED_FIELD',
    SET_PUBLIC_PROFILE = 'SET_PUBLIC_PROFILE',
    SET_PUBLIC_PROFILE_ABOUT_ME = 'SET_PUBLIC_PROFILE_ABOUT_ME',
    SET_ACTIVITY_DREAM = 'SET_ACTIVITY_DREAM',
    SET_ACTIVITY_ENERGY = 'SET_ACTIVITY_ENERGY',
    SET_ACTIVITY_SUPPORTED_DREAMS = 'SET_ACTIVITY_SUPPORTED_DREAMS',
    SET_ACTIVITY_REFERAL = 'SET_ACTIVITY_REFERAL',
    SET_ACHIEVEMENTS = 'SET_ACHIEVEMENTS',
}

enum actionsNames {
    auth = 'auth',
    registration = 'registration',
    fbRegistration = 'fbRegistration',
    fbAuth = 'fbAuth',
    logout = 'logout',
    refresh = 'refresh',
    regularRefresh = 'regularRefresh',
    verifyEmail = 'verifyEmail',
    autoAuthorization = 'autoAuthorization',
    fetchUserInfo = 'fetchUserInfo',
    sendEmail = 'sendEmail',
    sendNewUserData = 'sendNewUserData',
    sendChangePassword = 'sendChangePassword',
    sendProfileAboutMe = 'sendProfileAboutMe',
    createPassword = 'createPassword',
    recoveryPassword = 'recoveryPassword',
    fetchReferInfo = 'fetchReferInfo',
    getAdminAuthorisation = 'getAdminAuthorisation',
    setCurrencyOnSite = 'setCurrencyOnSite',
    setLanguageOnSite = 'setLanguageOnSite',
    getGoogleAuthUrl = 'getGoogleAuthUrl',
    authByGoogle = 'authByGoogle',
    getAppleAuthUrl = 'getAppleAuthUrl',
    authByApple = 'authByApple',
    getPublicProfile = 'getPublicProfile',
    getActivityDream = 'getActivityDream',
    getActivityEnergy = 'getActivityEnergy',
    getctivitySupportedDreams = 'getctivitySupportedDreams',
    getActivityReferal = 'getActivityReferal',
    getAchievements = 'getAchievements',
    getPublicAchievements = 'getPublicAchievements',
    checkLangInList = 'checkLangInList',
    setShareEmail = 'setShareEmail',
}

enum gettersNames {
    isImrixSubscribeActive = 'isImrixSubscribeActive',
}
/* eslint-enable */

const authMutations = {
    [mutationsNames.UPDATE_CURRENT]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_CURRENT,
    ),
    [mutationsNames.UPDATE_RESET_PASS_JWT]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_RESET_PASS_JWT,
    ),
    [mutationsNames.UPDATE_CONFIRM_EMAIL_TOKEN]: addModuleName(
        MODULE_NAME,
        mutationsNames.UPDATE_CONFIRM_EMAIL_TOKEN,
    ),
    [mutationsNames.RECEIVE_ACCESS_TOKEN]: addModuleName(
        MODULE_NAME,
        mutationsNames.RECEIVE_ACCESS_TOKEN,
    ),
    [mutationsNames.RECEIVE_FACEBOOK_TOKEN]: addModuleName(
        MODULE_NAME,
        mutationsNames.RECEIVE_FACEBOOK_TOKEN,
    ),
    [mutationsNames.RECEIVE_FB]: addModuleName(
        MODULE_NAME,
        mutationsNames.RECEIVE_FB,
    ),
    [mutationsNames.RECEIVE_USER]: addModuleName(
        MODULE_NAME,
        mutationsNames.RECEIVE_USER,
    ),
    [mutationsNames.RECEIVE_TRIAL_AFTER_SUBSCRIPTION]: addModuleName(
        MODULE_NAME,
        mutationsNames.RECEIVE_TRIAL_AFTER_SUBSCRIPTION,
    ),
    [mutationsNames.SET_NEW_PASSWORD_INFO]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_NEW_PASSWORD_INFO,
    ),
    [mutationsNames.SET_RECOVERY_PASSWORD]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_RECOVERY_PASSWORD,
    ),
    [mutationsNames.SET_REFCODE]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_REFCODE,
    ),
    [mutationsNames.SET_REFERAL]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_REFERAL,
    ),
    [mutationsNames.SET_TOKEN]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_TOKEN,
    ),
    [mutationsNames.SET_PERFOMANCE]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_PERFOMANCE,
    ),
    [mutationsNames.SET_USER_WITHOUT_SOME_REQUIRED_FIELD]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_USER_WITHOUT_SOME_REQUIRED_FIELD,
    ),
    [mutationsNames.SET_PUBLIC_PROFILE]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_PUBLIC_PROFILE,
    ),
    [mutationsNames.SET_PUBLIC_PROFILE_ABOUT_ME]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_PUBLIC_PROFILE_ABOUT_ME,
    ),
    [mutationsNames.SET_ACTIVITY_DREAM]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_ACTIVITY_DREAM,
    ),
    [mutationsNames.SET_ACTIVITY_ENERGY]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_ACTIVITY_ENERGY,
    ),
    [mutationsNames.SET_ACTIVITY_SUPPORTED_DREAMS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_ACTIVITY_SUPPORTED_DREAMS,
    ),
    [mutationsNames.SET_ACTIVITY_REFERAL]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_ACTIVITY_REFERAL,
    ),
    [mutationsNames.SET_ACHIEVEMENTS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_ACHIEVEMENTS,
    ),
};

const authActions = {
    [actionsNames.auth]: addModuleName(
        MODULE_NAME,
        actionsNames.auth,
    ),
    [actionsNames.registration]: addModuleName(
        MODULE_NAME,
        actionsNames.registration,
    ),
    [actionsNames.fbRegistration]: addModuleName(
        MODULE_NAME,
        actionsNames.fbRegistration,
    ),
    [actionsNames.fbAuth]: addModuleName(
        MODULE_NAME,
        actionsNames.fbAuth,
    ),
    [actionsNames.getGoogleAuthUrl]: addModuleName(
        MODULE_NAME,
        actionsNames.getGoogleAuthUrl,
    ),
    [actionsNames.authByGoogle]: addModuleName(
        MODULE_NAME,
        actionsNames.authByGoogle,
    ),
    [actionsNames.getAppleAuthUrl]: addModuleName(
        MODULE_NAME,
        actionsNames.getAppleAuthUrl,
    ),
    [actionsNames.authByApple]: addModuleName(
        MODULE_NAME,
        actionsNames.authByApple,
    ),
    [actionsNames.logout]: addModuleName(
        MODULE_NAME,
        actionsNames.logout,
    ),
    [actionsNames.refresh]: addModuleName(
        MODULE_NAME,
        actionsNames.refresh,
    ),
    [actionsNames.regularRefresh]: addModuleName(
        MODULE_NAME,
        actionsNames.regularRefresh,
    ),
    [actionsNames.verifyEmail]: addModuleName(
        MODULE_NAME,
        actionsNames.verifyEmail,
    ),
    [actionsNames.autoAuthorization]: addModuleName(
        MODULE_NAME,
        actionsNames.autoAuthorization,
    ),
    [actionsNames.fetchUserInfo]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchUserInfo,
    ),
    [actionsNames.sendEmail]: addModuleName(
        MODULE_NAME,
        actionsNames.sendEmail,
    ),
    [actionsNames.sendNewUserData]: addModuleName(
        MODULE_NAME,
        actionsNames.sendNewUserData,
    ),
    [actionsNames.sendProfileAboutMe]: addModuleName(
        MODULE_NAME,
        actionsNames.sendProfileAboutMe,
    ),
    [actionsNames.sendChangePassword]: addModuleName(
        MODULE_NAME,
        actionsNames.sendChangePassword,
    ),
    [actionsNames.createPassword]: addModuleName(
        MODULE_NAME,
        actionsNames.createPassword,
    ),
    [actionsNames.recoveryPassword]: addModuleName(
        MODULE_NAME,
        actionsNames.recoveryPassword,
    ),
    [actionsNames.fetchReferInfo]: addModuleName(
        MODULE_NAME,
        actionsNames.fetchReferInfo,
    ),
    [actionsNames.getAdminAuthorisation]: addModuleName(
        MODULE_NAME,
        actionsNames.getAdminAuthorisation,
    ),
    [actionsNames.setCurrencyOnSite]: addModuleName(
        MODULE_NAME,
        actionsNames.setCurrencyOnSite,
    ),
    [actionsNames.setLanguageOnSite]: addModuleName(
        MODULE_NAME,
        actionsNames.setLanguageOnSite,
    ),
    [actionsNames.getPublicProfile]: addModuleName(
        MODULE_NAME,
        actionsNames.getPublicProfile,
    ),
    [actionsNames.getActivityDream]: addModuleName(
        MODULE_NAME,
        actionsNames.getActivityDream,
    ),
    [actionsNames.getActivityEnergy]: addModuleName(
        MODULE_NAME,
        actionsNames.getActivityEnergy,
    ),
    [actionsNames.getctivitySupportedDreams]: addModuleName(
        MODULE_NAME,
        actionsNames.getctivitySupportedDreams,
    ),
    [actionsNames.getActivityReferal]: addModuleName(
        MODULE_NAME,
        actionsNames.getActivityReferal,
    ),
    [actionsNames.getAchievements]: addModuleName(
        MODULE_NAME,
        actionsNames.getAchievements,
    ),
    [actionsNames.getPublicAchievements]: addModuleName(
        MODULE_NAME,
        actionsNames.getPublicAchievements,
    ),
    [actionsNames.checkLangInList]: addModuleName(
        MODULE_NAME,
        actionsNames.checkLangInList,
    ),
    [actionsNames.setShareEmail]: addModuleName(
        MODULE_NAME,
        actionsNames.setShareEmail,
    ),
};

const authGetters = {
    [gettersNames.isImrixSubscribeActive]: addModuleName(
        MODULE_NAME,
        gettersNames.isImrixSubscribeActive,
    ),
};

export {
    actionsNames,
    authActions, authGetters, authMutations, gettersNames, mutationsNames,
};

