import Vue from 'vue';
import store from '../../store';
import VueNativeSocket from 'vue-native-websocket';
import { deepParseJson } from 'deep-parse-json';
import { websocketMutations } from '../../store/modules/websocket/names';
import { notificationsMutations } from '../../store/modules/notifications/names';
import { authMutations } from '../../store/modules/auth/names';
import { consts } from '../../types';

class WebsocketEvent extends Event {
    data?: string = '';
}

interface IDataEvent {
    label?: string;
    count?: number;
    channel?: string;
    data?: any;
}

interface ITypes {
    UNCONFIRMED_DONATIONS: 'donations',
    UNREAD_EVENTS: 'all',
    UNREAD_MESSAGES: 'messages',
}

Vue.use(VueNativeSocket, null, {
    store,
    format: 'json',
    connectManually: true,
    reconnection: false,
    passToStoreHandler(name: string, event: WebsocketEvent) {
        const eventName = name.toUpperCase();

        if (eventName === 'SOCKET_ONCLOSE') {
            onCloseEventHanlder();
        }

        if (eventName === 'SOCKET_ONOPEN') {
            onOpenEventHandler();
        }

        if (!event.data) return;

        const dataEvent: IDataEvent = deepParseJson(event.data);
        const eventType: string | null = dataEvent.label ? dataEvent.label.toUpperCase() : null;

        if (!name.startsWith('SOCKET_')) return;

        if (eventType === 'NEW_ACHIEVEMENT') {
            onNewAchievementEventHandler(dataEvent);
        }

        onSetCountersHandler(dataEvent, eventType);

        if (eventType === 'RESET_TIMER') {
            onResetTimerEventHandler();
        }
    },
});

function onCloseEventHanlder() {
    return store.commit(websocketMutations.SOCKET_ONCLOSE);
}

function onOpenEventHandler() {
    try {
        Vue.prototype.$socket.send(
            JSON.stringify({ event: 'counts' }),
        );

        setInterval(() => {
            Vue.prototype.$socket.send('ping');
        }, 10000);
    
        return store.commit(websocketMutations.SOCKET_ONOPEN);
    } catch (_) {
        console
            .error('Events SOCKET_ONOPEN in sockets failed. Check are sockets connected');
    }
}

function onNewAchievementEventHandler(dataEvent: IDataEvent) {
    store.commit(notificationsMutations.SHOW_ACHIEVEMENT_MODAL, true);
    store.commit(notificationsMutations.SET_ACHIEVEMENT, { ...dataEvent.data });

    const { achievements } = store.state.auth;
    const newAchievement = store.state.notifications.achievement;
    const { user } = store.state.auth;
            
    if (achievements?.length && user?.id === newAchievement?.user_id) {
        const updatedAchievements = achievements?.map((achievement) => {
            if (achievement?.id === newAchievement?.id) {
                return { ...achievement, received_at: newAchievement?.received_at };
            }
            return achievement;
        });

        store.commit(authMutations.SET_ACHIEVEMENTS, updatedAchievements);
    }
}

function onSetCountersHandler(dataEvent: IDataEvent, eventType: string | null) {
    const types: ITypes = {
        UNCONFIRMED_DONATIONS: 'donations',
        UNREAD_EVENTS: 'all',
        UNREAD_MESSAGES: 'messages',
    };

    if (eventType && types[eventType]) {
        store.commit(notificationsMutations.RECEIVE_COUNTER, {
            field: types[eventType],
            value: dataEvent.count,
        });
    }
}

function onResetTimerEventHandler() {
    localStorage.removeItem(consts.SHORTCUT_DREAM);
    window.location.reload();
}