import { MutationTree } from 'vuex';
import { mutationsNames } from './names';
import { ILogState } from './types';

export const mutations: MutationTree<ILogState> = {
    [mutationsNames.SET_LOGS](state, payload) {
        state.logs = payload;
    },
    [mutationsNames.SET_TOTAL_LOGS](state, payload: number) {
        state.total = payload;
    },
    [mutationsNames.SET_NOTIFICATIONS_LOGS](state, payload) {
        state.notificationsLogs = payload;
    },
    [mutationsNames.SET_TOTAL_NOTIFICATIONS_LOGS](state, payload: number) {
        state.notificationsTotal = payload;
    },
};