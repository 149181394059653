
import Vue from 'vue';
import { mapState } from 'vuex';

import VLangManagerIcon from '../icons/VLangManagerIcon.vue';

import { ComposedState } from '@/types';

interface IDrawerItem {
    to: string;
    title: string;
    icon: string;
    onlySuperUser?: boolean;
};

export default Vue.extend({
    name: 'Drawer',

    components: { VLangManagerIcon },

    data() {
        return {
            drawer: true,

            items: [
                {
                    to: '/dashboard',
                    title: 'Home',
                    icon: 'mdi-home-assistant',
                },

                {
                    to: '/users',
                    title: 'Users',
                    icon: 'mdi-account-multiple',
                    onlySuperUser: true,
                },

                {
                    to: '/operators',
                    title: 'Operators',
                    icon: 'mdi-account-group',
                    onlySuperUser: true,
                },

                {
                    to: '/news',
                    title: 'Blog',
                    icon: 'mdi-clipboard-text-multiple-outline',
                    onlySuperUser: false,
                },

                {
                    to: '/notifications',
                    title: 'Notifications',
                    icon: 'mdi-bell',
                    onlySuperUser: true,
                },

                {
                    to: '/usersWithStatus',
                    title: 'Special users',
                    icon: 'mdi-account-multiple',
                    onlySuperUser: true,
                },

                {
                    to: '/dreams',
                    title: 'Dreams',
                    icon: 'mdi-account-multiple',
                    onlySuperUser: true,
                },

                {
                    to: '/donation',
                    title: 'Donation',
                    icon: 'mdi-account-multiple',
                    onlySuperUser: true,
                },

                {
                    to: '/currency',
                    title: 'Currency',
                    icon: 'mdi-chart-areaspline',
                    onlySuperUser: true,
                },

                {
                    to: '/reviews',
                    title: 'Reviews',
                    icon: 'mdi-comment-text-multiple-outline',
                    onlySuperUser: true,
                },

                {
                    to: '/likes',
                    title: 'Likes Manage',
                    icon: 'mdi-heart-outline',
                    onlySuperUser: true,
                },

                {
                    to: '/views',
                    title: 'View Management',
                    icon: 'mdi-eye-outline',
                    onlySuperUser: true,
                },

                {
                    to: '/langs',
                    title: 'Language Management',
                    onlySuperUser: true,
                },

                {
                    to: '/support-chat',
                    title: 'Support chat',
                    icon: 'mdi-wechat',
                    onlySuperUser: true,
                },
            ] as IDrawerItem[],
        };
    },

    computed: {
        ...mapState<ComposedState>({
            admin: (state: ComposedState) => state.admin.admin,
        }),

        nav(): IDrawerItem[] {
            const itemsList: IDrawerItem[] = [];
            this.items.forEach((el) => {
                // @ts-ignore
                if (!el.onlySuperUser || this.admin?.isSuperuser) {
                    itemsList.push(el);
                }
            });

            return itemsList;
        },
    },
});
