import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import users from './modules/users/state';
import operators from './modules/operators/state';
import admin from './modules/admin/state';
import news from './modules/news/state';
import general from './modules/general/state';
import dashboard from './modules/dashboard/state';
import dreams from './modules/dreams/state';
import donations from './modules/donations/state';
import currency from './modules/currency/state';
import websocket from './modules/websocket/state';

// @ts-ignore
import logs from './modules/log/state';

import { ComposedState } from '@/types';

Vue.use(Vuex);

const store: StoreOptions<ComposedState> = {
    modules: {
        donations,
        dreams,
        users,
        admin,
        operators,
        news,
        general,
        dashboard,
        currency,
        websocket,
        logs,
    },
};

export default new Vuex.Store<ComposedState>(store);
