import { initApp } from '@/tools/initApp';
import Vue from 'vue';
import Router, { NavigationGuardNext, Route } from 'vue-router';

import LoginView from '@/views/Login.vue';
import Operators from '@/views/Operators.vue';
import OperatorItem from '@/views/OperatorItem.vue';
import NotFound from '@/views/NotFound.vue';
import usersWithStatus from '@/views/users/UsersWithStatus.vue';

import store from '@/store';
import { generalActions, generalMutations } from '@/store/modules/general/names';

const home = () => import('@/views/index.vue');
const dashboard = () => import('@/views/dashboard/index.vue');
const dashboardDefault = () => import('@/views/dashboard/Dashboard.vue');
const news = () => import('@/views/news/index.vue');
const newsList = () => import('@/views/news/News.vue');
const newsAdd = () => import('@/views/news/AddNews.vue');
const newsEdit = () => import('@/views/news/EditNews.vue');
const users = () => import('@/views/users/index.vue');
const usersList = () => import('@/views/users/Users.vue');
const userItem = () => import('@/views/users/UserItem.vue');
const dreams = () => import('@/views/dreams/index.vue');
const dreamsList = () => import('@/views/dreams/Dreams.vue');
const dreamCreate = () => import('@/views/dreams/DreamCreate.vue');
const dreamItem = () => import('@/views/dreams/DreamItem.vue');
const currenciesList = () => import('@/views/currency/CurrenciesList.vue');
const donation = () => import('@/views/donation/index.vue');
const donationList = () => import('@/views/donation/Donation.vue');
const reviews = () => import('@/views/reviews/index.vue');
const reviewsTable = () => import('@/views/reviews/ReviewsTable.vue');
const likes = () => import('@/views/likes/index.vue');
const likesManager = () => import('@/views/likes/LikesManager.vue');
const views = () => import('@/views/views/index.vue');
const viewsManagement = () => import('@/views/views/ViewsManagement.vue');
const langs = () => import('@/views/language/index.vue');
const LangManager = () => import('@/views/language/LangManager.vue');
const notifications = () => import('@/views/notifications/index.vue');
const notificationsPage = () => import('@/views/notifications/Notifications.vue');
const notificationsLogs = () => import('@/views/notifications/Logs.vue');
const supportChat = () => import('@/views/support-chat/index.vue');

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'login',
            component: LoginView,
            meta: {
                layout: 'login',
            },
        },
        {
            path: '/',
            name: 'home',
            redirect: '/dashboard',
            component: home,
            children: [
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: dashboard,
                    redirect: '/dashboard/default',
                    children: [
                        {
                            path: 'default',
                            component: dashboardDefault,
                            name: 'dashboardDefault',
                        },
                    ],
                },
                {
                    path: '/operators',
                    name: 'Operators',
                    component: Operators,
                },
                {
                    path: '/operators/:id',
                    name: 'OperatorItem',
                    component: OperatorItem,
                },
                {
                    path: '/news',
                    name: 'News',
                    component: news,
                    redirect: '/news/list',
                    children: [
                        {
                            path: 'list',
                            component: newsList,
                            name: 'news-list',
                        },
                        {
                            path: 'add',
                            component: newsAdd,
                            name: 'news-add',
                        },
                        {
                            path: 'edit/:id',
                            component: newsEdit,
                            name: 'news-edit',
                        },
                    ],
                },
                {
                    path: '/users',
                    name: 'users',
                    redirect: '/users/list',
                    component: users,
                    children: [
                        {
                            path: 'list',
                            name: 'users-list',
                            component: usersList,
                        },
                        {
                            path: ':id',
                            name: 'UserItem',
                            component: userItem,
                        },
                    ],
                },
                {
                    path: '/usersWithStatus',
                    name: 'usersWithStatus',
                    component: usersWithStatus,
                },
                {
                    path: '/dreams',
                    name: 'dreams',
                    redirect: '/dreams/list',
                    component: dreams,
                    children: [
                        {
                            path: 'list',
                            name: 'dreamsList',
                            component: dreamsList,
                        },
                        {
                            path: ':id',
                            name: 'dreamItem',
                            component: dreamItem,
                        },
                        {
                            path: 'dreamCreate',
                            name: 'dreamCreate',
                            component: dreamCreate,
                        },
                    ],
                },

                {
                    path: '/donation',
                    name: 'donation',
                    redirect: '/donation/list',
                    component: donation,
                    children: [
                        {
                            path: 'list',
                            name: 'donationList',
                            component: donationList,
                        },
                    ],
                },
                {
                    path: '/currency',
                    name: 'CurrenciesList',
                    component: currenciesList,
                },
                {
                    path: '/reviews',
                    name: 'Reviews',
                    redirect: '/reviews/table',
                    component: reviews,
                    children: [
                        {
                            path: 'table',
                            name: 'reviewsTable',
                            component: reviewsTable,
                        },
                    ],
                },
                {
                    path: '/likes',
                    name: 'Likes',
                    redirect: '/likes/info',
                    component: likes,
                    children: [
                        {
                            path: 'info',
                            name: 'likesManager',
                            component: likesManager,
                        },
                    ],
                },
                {
                    path: '/views',
                    name: 'Views',
                    redirect: '/views/info',
                    component: views,
                    children: [
                        {
                            path: 'info',
                            name: 'viewsManagement',
                            component: viewsManagement,
                        },
                    ],
                },
                {
                    path: '/langs',
                    name: 'Language Managment',
                    redirect: '/langs/table',
                    component: langs,
                    children: [
                        {
                            path: '/langs/table',
                            name: 'LangTable',
                            component: LangManager,
                        },
                    ],
                },

                {
                    path: '/notifications',
                    name: 'notifications',
                    redirect: '/notifications/page',
                    component: notifications,
                    children: [
                        {
                            path: 'page',
                            name: 'notificationsPage',
                            component: notificationsPage,
                        },
                        {
                            path: 'logs',
                            name: 'notificationsLogs',
                            component: notificationsLogs,
                        },
                    ],
                },

                {
                    path: '/support-chat',
                    name: 'Support chat',
                    component: supportChat,
                },
            ],
        },
        {
            path: '*',
            name: '404',
            component: NotFound,
        },
    ],
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
    store.commit(generalMutations.RESET_ERRORS);
    store.dispatch(generalActions.uploadReset);

    const login = store.state.admin;

    const { initialization } = store.state.general;

    if (initialization) {
        await initApp(store);
    }

    const publicPages = ['/login'];
    const authRequired = publicPages.includes(to.path);

    if (!login.access) {
        if (!authRequired) {
            return next('/login');
        }

        return next();
    }

    if (to.path === '/login' && !initialization) {
        return next('/');
    }

    if (login.access && to.path === '/login') {
        return next('/');
    }

    next();
});

export default router;
